import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { navigate } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import { FiSearch } from 'react-icons/fi'
import parse from 'html-react-parser'
import { useLocation } from '@reach/router'
import { Container, Row, Col } from '../ui/wrapper'
import Heading from '../ui/heading'
import Text from '../ui/text'
import Button from '../ui/button'
import { useMedia } from 'react-use-media'
import { theme, size } from '../../theme'

import {
  HeroWrapper,
  HeroRow,
  HeroCol,
  ImageCol,
  HeroTextBox,
  ImageBoxWrap,
  SearchWrapper,
  SearchButton,
  Date,
  BreadcrumbWrapper,
} from './hero-area.style'

const getDisableLinks = (pathname, { pathname: last }) => {
  const disableLinks = [
    '/elements',
    '/category',
    '/profile',
    '/date',
    '/tag',
    '/page',
    '/blog',
    '/blog/page',
    '/blogs',
    '/services',
  ]
  return pathname.endsWith(last) ? [last, ...disableLinks] : disableLinks
}

const HeroArea = ({
  dot,
  showLastLable,
  pageContext,
  title,
  crumbTitle,
  date,
  description,
  buttonlink,
  buttontext,
  color,
  background,
  image,
  search,
  flyoutHandler,
  titleStyle,
  btnStyle,
  ...rest
}) => {
  const [value, setValue] = useState()
  const { pathname } = useLocation()
  const isMobile = useMedia({ maxWidth: size.xxsmall })
  const crumbs = pageContext
    ? pageContext.breadcrumb.crumbs
        .map((crumb, index) => {
          crumb.crumbLabel = crumb.crumbLabel
            .replace('-', ' ')
            .replace('rwb', 'RWB')
          crumb.crumbLabel =
            crumb.crumbLabel.charAt(0).toUpperCase() + crumb.crumbLabel.slice(1)
          return crumb
        })
        .filter((crumb, index) => {
          return (
            index < 3 &&
            (showLastLable ||
              (!showLastLable &&
                pageContext.breadcrumb.crumbs.length !== index + 1))
          )
        })
    : false

  const handleInput = (event) => {
    setValue(event.target.value)
  }

  const handlSubmit = (event) => {
    event.preventDefault()
    navigate(`/zoeken`, { state: { query: value } })
  }

  const heroAltText = image?.description ?? title

  return (
    <HeroWrapper {...rest}>
      <Container className="container-xl" fluid>
        {crumbs && (
          <BreadcrumbWrapper>
            <Container fluid="lg">
              <Breadcrumb
                crumbs={crumbs}
                disableLinks={getDisableLinks(
                  pathname,
                  crumbs[crumbs.length - 1],
                )}
                crumbSeparator="›"
                {...(showLastLable
                  ? { crumbLabel: crumbTitle ? crumbTitle : title }
                  : {})}
              />
            </Container>
          </BreadcrumbWrapper>
        )}

        <HeroRow
          alignitems="end"
          color={color}
          {...(dot ? { dot: 'true' } : {})}
        >
          <HeroCol color={color} md={6}>
            <HeroTextBox>
              {date && <Date>{date}</Date>}
              {title && (
                <Heading color="white" {...titleStyle}>
                  {parse(title)}
                </Heading>
              )}
              {description && <Text color="white">{description}</Text>}
              {buttonlink && buttontext && (
                <Button skin="light" {...btnStyle} to={buttonlink}>
                  {buttontext}
                </Button>
              )}
            </HeroTextBox>
          </HeroCol>
          <ImageCol md={6}>
            <ImageBoxWrap background={background} color={color}>
              {image && image.fluid && (
                <Img fluid={image.fluid} alt={heroAltText} />
              )}

              {image && !image.fluid && image.file && image.file.url && (
                <img src={image.file.url} alt={heroAltText} title={title} />
              )}
            </ImageBoxWrap>
          </ImageCol>
        </HeroRow>
      </Container>

      {search && (
        <SearchWrapper>
          <Row>
            <Col>
              <form onSubmit={handlSubmit}>
                <SearchButton aria-label="Zoeken">
                  <FiSearch role="img" title="Zoeken" />
                  <input
                    id="hero-search-bar"
                    onChange={handleInput}
                    type="text"
                    name="Zoeken"
                    placeholder={
                      isMobile ? 'Zoeken' : 'Waar bent u naar op zoek?'
                    }
                  />
                </SearchButton>
              </form>
            </Col>
          </Row>
        </SearchWrapper>
      )}
    </HeroWrapper>
  )
}

HeroArea.propTypes = {
  dot: PropTypes.bool,
  showLastLable: PropTypes.bool,
  date: PropTypes.string,
  subtitleStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  btnStyle: PropTypes.object,
  color: PropTypes.string,
  background: PropTypes.bool,
  search: PropTypes.bool,
}

HeroArea.defaultProps = {
  search: false,
  showLastLable: true,
  dot: false,
  color: theme.colors.theme,
  background: false,
  subtitleStyle: {
    as: 'h6',
    color: 'primary',
    fontweight: 500,
    mb: '20px',
  },
  titleStyle: {
    as: 'h1',
    mb: '15px',
    fontweight: 500,
  },
  btnStyle: {
    hover: '2',
    mt: '15px',
  },
}

export default HeroArea
