import styled, { css } from 'styled-components'
import { Container, Row, Col } from '../ui/wrapper'
import { device } from '../../theme'

export const HeroWrapper = styled.section`
  &.highlight h1 {
    font-weight: 300;
  }

  > * {
    position: relative;
  }
`

export const HeroRow = styled(Row)`
  position: relative;

  ${(props) =>
    props.dot &&
    css`
      @media ${device.mediumOnly} {
        &::before,
        &::after {
          content: '';
          position: absolute;
          background-color: ${(props) =>
            props.color || props.theme.colors.theme};
          transform: translateY(100%);
          z-index: 10;
        }

        &::after {
          right: 0;
          bottom: 0;
          width: 100px;
          height: 100px;
          border-radius: 0 0 0 200px;
        }

        &::before {
          bottom: -240px;
          left: 0;
          height: 150px;
          width: 75px;
          border-radius: 0 150px 150px 0;
        }
      }
    `}
`

export const HeroCol = styled(Col)`
  overflow: hidden;
  position: relative;
  z-index: 2;

  @media ${device.large} {
    background-color: ${(props) => props.color};
  }

  @media screen and ${device.largeOnly} {
    margin-top: 100px;
    min-height: 380px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 200px;
      height: 200px;
      border-radius: 200px;
      transform: translate(50%, -50%);
      box-shadow: 0 0 0 1000px ${(props) => props.color};
    }
  }

  @media print {
    background-color: transparent;

    &::before {
      background-color: transparent;
    }

    h1 {
      color: ${(props) => props.theme.colors.text} !important;
    }
  }
`

export const ImageCol = styled(Col)`
  padding: 0;

  @media ${device.smallOnly} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
  }

  @media ${device.largeOnly} {
    right: 0;
    max-width: 900px;
  }
`

export const HeroTextBox = styled.div`
  position: relative;
  z-index: 1;
  max-width: 570px;
  width: 100%;
  padding: 40px 20px 50px 0;
  margin-left: auto;

  @media ${device.mediumOnly} {
    padding-bottom: 70px;
  }

  @media ${device.largeOnly} {
    padding-top: 60px;
    padding-right: 100px;
    padding-bottom: 100px;
  }

  @media ${device.small} {
    margin-left: auto;
    margin-right: auto;
  }

  a:hover,
  a:active,
  a:focus {
    color: ${(props) => props.theme.colors.theme} !important;
    background-color: ${(props) => props.theme.colors.white} !important;
  }
`

export const ImageBoxWrap = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  text-align: center;

  ${(props) =>
    props.background &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: ${(props) => (props.color ? props.color : 'transparent')};
        opacity: 0.2;
      }
    `}

  img {
    width: 100%;

    @supports (object-fit: cover) {
      height: 100%;
      min-height: 300px;
      object-fit: cover;
    }
  }

  > img {
    position: relative;
    z-index: 1;
    width: 280px;

    @media ${device.smallOnly} {
      width: 100%;
      max-width: 630px;

      @supports (object-fit: contain) {
        margin-top: 5%;
        height: 90%;
        max-width: none;
        object-fit: contain;
      }
    }

    @media ${device.largeOnly} {
      margin-left: 20%;
      width: 80%;
    }

    @media ${device.xlargeOnly} {
      margin-left: 10%;
      width: 90%;
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
  }

  @media ${device.small} {
    padding-top: 0;
    margin-left: auto;
    margin-right: auto;
    max-height: 250px;
  }
`

export const SearchWrapper = styled(Container)`
  height: 0;
`

export const SearchButton = styled.label`
  cursor: text;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 30px;
  font-size: 20px;
  background: ${(props) => props.theme.colors.white};
  border: none;
  z-index: 4;
  transform: translateY(-50%);
  border-radius: 100px;

  svg {
    color: ${(props) => props.theme.colors.theme};
    font-size: 22px;
    margin-right: 20px;
    min-width: 20px;
  }

  form,
  input {
    width: calc(100% - 40px);
  }

  input {
    border: none;
    height: 24px;
    line-height: 24px;
    padding: 0;

    &,
    &:focus {
      color: ${(props) => props.theme.colors.textDark} !important;
    }
  }

  > label {
    display: none;
  }

  @media ${device.largeOnly} {
    padding: 40px;
  }
`

export const Date = styled.div`
  position: relative;
  margin-bottom: 4px;
  padding-left: 74px;
  font-size: 14px;
  line-height: 26px;
  color: ${(props) => props.theme.colors.white};

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: 60px;
    border-bottom: 2px solid ${(props) => props.theme.colors.white};
    transform: translateY(-1px);
  }

  &,
  + h1 {
    text-align: left;
  }

  @media print {
    padding-left: 0;
    color: ${(props) => props.theme.colors.text} !important;
  }
`

export const BreadcrumbWrapper = styled.div`
  z-index: 2;

  @media ${device.largeOnly} {
    ${(props) =>
      props.position !== 'relative'
        ? css`
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
          `
        : css`
            padding-top: 80px;
          `}
  }

  .breadcrumb {
    padding: 0;
    background-color: transparent;

    ${(props) =>
      props.align === 'center' &&
      css`
        justify-content: center;
      `}

    ${(props) =>
      props.position === 'relative' &&
      css`
        margin-bottom: 0;
      `}

        @media ${device.large} {
      padding-top: 15px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      list-style: none;

      &__item {
        margin-right: 6px;

        a,
        span {
          font-family: ${(props) => props.theme.fontFamily.heading};
          color: ${(props) => props.theme.colors.textDark};
          font-weight: normal;
        }

        &:last-child {
          a,
          span {
            color: ${(props) => props.theme.colors.text};
            font-weight: lighter;
          }
        }

        a:hover {
          color: ${(props) => props.theme.colors.theme};
          text-decoration: underline;
        }
      }
    }

    &__separator {
      margin-right: 6px;
    }
  }
`
