import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SEO from '../components/seo'
import Header from '../containers/header'
import Footer from '../containers/footer'
import Hero from '../components/hero'
import BoxImageLarge from '../containers/elements/box-large-image/section-home'

const IndexPage = ({ pageContext, location }) => {
  const pageData = useStaticQuery(graphql`
    query SubsidypageQuery {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl
          defaultImage: image
        }
      }

      page: allContentfulPagina(
        filter: { id: { eq: "4ee1cb90-6d1f-5a56-ae12-5f8100c960da" } }
      ) {
        edges {
          node {
            headerTitel
            headerSubtitel
            headerButtonTekst
            headerButtonLink
            headerImage {
              file {
                url
                fileName
                contentType
              }
              fluid(maxWidth: 900) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }

      subsidy: allContentfulSubsidie(
        limit: 4
        sort: { fields: date, order: DESC }
        filter: {
          title: { ne: null }
          slug: { regex: "/^(?!untitled-entry).+/" }
          date: { ne: null }
        }
      ) {
        edges {
          node {
            id
            title
            date(locale: "nl_NL", formatString: "DD MMMM YYYY")
            slug
            image {
              fluid(maxWidth: 260, cropFocus: CENTER) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }

      newsArticles: allContentfulNieuwsbericht(
        sort: { fields: date, order: DESC }
        filter: {
          title: { ne: null }
          slug: { regex: "/^(?!untitled-entry).+/" }
          date: { ne: null }
        }
      ) {
        edges {
          node {
            id
            title
            date(locale: "nl_NL", formatString: "DD MMMM YYYY")
            slug
            image {
              fluid(maxWidth: 260, cropFocus: CENTER) {
                ...GatsbyContentfulFluid_withWebp
              }
              title
              description
            }
            themes {
              id
              title
            }
            isSubsidyNews
          }
        }
      }
    }
  `)

  const metadata = pageData.site.siteMetadata
  metadata.url = `${metadata.siteUrl}/subsidieloket`
  const latestSubsidies = pageData.subsidy.edges.map((edge) => edge.node)
  const subsidyNewsArticles = pageData.newsArticles.edges
    .map((edge) => edge.node)
    .filter((article) => article.isSubsidyNews === true)
    .slice(0, 4)
  const {
    headerTitel,
    headerSubtitel,
    headerButtonTekst,
    headerButtonLink,
    headerImage,
  } = pageData.page.edges[0].node
  const [flyoutOpen, setFlyoutOpen] = useState(false)

  const flyoutHandler = () => {
    setFlyoutOpen((prevState) => !prevState)
  }

  return (
    <>
      <SEO
        title={`${headerTitel}`}
        description={headerSubtitel || headerTitel}
        image={headerImage?.file?.url}
        metadata={metadata}
      />

      <Header
        transparent
        flyoutOpen={flyoutOpen}
        flyoutHandler={flyoutHandler}
      />
      <main id="main" className="site-wrapper-reveal">
        <Hero
          flyoutHandler={flyoutHandler}
          title={headerTitel}
          description={headerSubtitel}
          buttontext={headerButtonTekst}
          buttonlink={headerButtonLink}
          image={headerImage}
          pageContext={pageContext}
        />

        <BoxImageLarge
          path="/subsidieloket/subsidies"
          title="Subsidies"
          buttontext="subsidies"
          items={latestSubsidies}
          split={true}
          detailButtonText={'Meer informatie'}
        />

        <BoxImageLarge
          items={subsidyNewsArticles}
          title="Laatste subsidienieuws"
          padding={false}
        />
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
